body {
  background-color: transparent;
  background-color: transparent;
}

.requiredfield::after {
  content: "*";
  color: red;
}

.font-size-large {
  font-size: larger;
}

#page-topbar {
  background-color: #fff !important;
}

.main-content {
  background-color: #f2f2f2;
  border-radius: 12px 0px 0px 0px;
  margin-top: 71px;
}

.page-content {
  padding: 0px calc(1.5rem / 2) 60px calc(1.5rem / 2);
}

.page-content .photoCard {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.page-content .photoCard .d-flex {
  overflow-x: auto !important;
  /* Enable horizontal scrolling if necessary */
}

.page-content .photoCard .mb-5,
.page-content .photoCard .mb-2 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.page-content .photoCard label {
  margin-bottom: 0.5rem !important;
}

.page-content .photoCard .form-control {
  width: 100% !important;
}

#sidebar-menu ul li a {
  border-radius: 0px 50px 50px 0px;
  margin-right: 20px;
  border-radius: 0px 50px 50px 0px;
  margin-right: 20px;
}

.mm-active > a,
.mm-active .active {
  background-color: rgb(0 102 171 / 10%) !important;
  color: black !important;
}

.mm-active .active i,
.mm-active > a i {
  color: #00a0e3 !important;
}

.wizard ul {
  display: grid;
}

.number {
  position: relative;
}

.number::after {
  background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%);
  content: "";
  display: block;
  width: 2px;
  height: 39px;
  position: absolute;
  bottom: -43px;
  right: 45%;
}

#layout-wrapper
  > div.main-content
  > div.page-content
  > div
  > div
  > div.p-2.col-12.col-md-12.col-lg-4
  > div
  > div
  > ul
  > li:last-child
  > a
  > span::after {
  display: none;
}

.border-right-top {
  border-radius: 12px 0px 0px 0px;
  border-radius: 12px 0px 0px 0px;
}

.wizard > .steps {
  background-color: #fff;
  padding: 0.8rem;
}

.wizard .steps .nav-link {
  background-color: transparent;
  color: #000;
  display: flex !important;
  background-color: transparent;
  color: #000;
  display: flex !important;
}

.wizard > .steps > ul > li {
  width: auto;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover {
  background-color: transparent;
  color: #000;
}

.wizard > .steps .number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 38px;
  background-color: transparent;
  border: 2px solid #0066ab;
}

.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
  background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 2px solid #0066ab;
}

.steps .current a .number.activetab::before {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
}

.wizard > .steps .number.completetab {
  background-color: #00bd4c;
  border-color: #00bd4c;
}

.wizard > .steps .number.completetab i {
  font-weight: bold;
  color: #fff;
}

.steptext span {
  padding: 2px 10px;
  border: 1px solid #8e8e8e;
  border-radius: 25px;
  color: #8e8e8e;
}

.activestatus {
  background: #e5f0f7;
  color: #0066ab !important;
  border: 1px solid #e5f0f7 !important;
}

.completestatus {
  background: #00bd4c33;
  color: #0066ab !important;
  border: 1px solid #00bd4c33 !important;
}

#layout-wrapper
  > div.main-content
  > div
  > div
  > div
  > div.p-2.col-sm-3
  > div
  > div
  > ul
  > li:last-child
  > a
  > span::after {
  display: none;
}

.progress {
  height: 7px;
}

.form-wizard-wrapper label {
  text-align: left;
}

.btnActive {
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.2),
    rgba(0, 102, 171, 0.2)
  );
  border: 1px solid transparent;
  color: rgba(0, 102, 171, 1);
}

.btnActive:hover {
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.2),
    rgba(0, 102, 171, 0.2)
  );
  color: rgba(0, 102, 171, 1);
}

.btnNormal {
  background-color: transparent;
  border: 1px solid rgba(142, 142, 142, 1);
  color: rgba(83, 83, 83, 1);
}

.btnNormal:hover {
  background-color: transparent;
  border: 1px solid rgba(142, 142, 142, 1);
  color: rgba(83, 83, 83, 1);
}

.wizard .actions > ul {
  display: flex !important;
  text-align: right !important;
  padding-left: 0 !important;
  width: 100%;
  justify-content: space-between;
}

.uploadgradient {
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.1),
    rgba(0, 102, 171, 0.1)
  );
}

.commongradient {
  background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%);
}

.previousStep {
  background-color: transparent !important;
  border: 1px solid #0066ab;
  color: #0066ab !important;
}

.grid-columns {
  display: grid;
  gap: 5px 5px;
  grid-template-columns: auto auto auto auto;
}

.amenitiesgrid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 5px;
}

@media (max-width: 991px) {
  .amenitiesgrid {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .amenitiesgrid button {
    max-width: calc(auto) !important;
    /* Adjust percentage as needed */
    margin: auto !important;
  }
}

@media (max-width: 575px) {
  .amenitiesModal {
    padding: 20px !important;
    margin: auto !important;
  }
}

@media (max-width: 436px) {
  .amenitiesModal .modal-body {
    max-height: 80vh !important;
    /* Set a maximum height for the modal body */
    overflow-y: auto !important;
    /* Enable vertical scrolling for the modal body */
  }
}

@media (max-width: 380px) {
  .amenititiesModal {
    width: 100% !important;
  }
}

/* ============== custom login =============== */

body {
  font-family: "Nunito Sans" !important;
}

.freehousing_button {
  background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%) !important;
  color: "#fff" !important;
}

.loginRegInput {
  padding: 10px;
  border: 1px solid #80808094;
  border-radius: 4px;
}

.loginregwrap {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  /* margin-top: 10px; */
}

.loginreglabel {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
  font-size: "14px";
}

.logindivborder {
  height: 2px;
  background: #80808082;
  width: 50%;
}

.loginregwrapbot {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.loginRegInbot {
  padding: 12px;
  border: 1px solid #80808094;
  border-radius: 4px;
}

.logbotbutton {
  width: 100%;
  background: #fff !important;
  color: #8e8e8e !important;
  border: 1px solid #80808094 !important;
  box-shadow: none !important;
  margin-top: 20px;
  text-transform: none !important;
}

.linkcomponnet {
  text-decoration: none;
  color: #8e8e8e;
}

.mainpagebutton {
  color: #3d3d3d;
  margin-right: 7px;
}

.outerwrap {
  box-shadow: 0px 4px 4px 0px #dadada40;
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
}

/* .backgroundImageContainer {
  
    color: #535353;
    /* background-image: url("./components/mainpage/mainpagebackground.png");
    background-size: cover;
    background-position: center;
  } */

/* .form-check-input:checked {
    background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%) !important;
    border-color: #626ed4;
} */
.amenitiesvalue {
  padding: 5px;
}

#layout-wrapper
  > div.main-content
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > table
  > thead:nth-child(3) {
  display: none;
}

.addbutton {
  border-radius: 5px;
  padding: 8px 20px;
  color: #fff;
}

#layout-wrapper
  > div.main-content
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div
  > table
  > tfoot
  p {
  margin: 0px;
}

#layout-wrapper
  > div.main-content
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div {
  box-shadow: none;
}

/* .MuiButtonBase-root.MuiButton-root {
  justify-content: left;
  padding: 0px;
} */

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  min-height: 0px;
  overflow-x: auto !important;
}

.actionview {
  margin: 3px !important;
  border: none;
  border-radius: 5px;
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.2),
    rgba(0, 102, 171, 0.2)
  );
}

.actionedit {
  border: none;
  border-radius: 5px;
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.2),
    rgba(0, 102, 171, 0.2)
  );
  border: none;
  border-radius: 5px;
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.2),
    rgba(0, 102, 171, 0.2)
  );
}

/* ============= single prop grid block ============= */
.hoverbtn {
  color: #000;
}

.hoverbtn:hover {
  color: #fff;
}

.filtermodal.modal.show .modal-dialog {
  width: 50% !important;
}

@media (max-width: 750px) {
  .filtermodal.modal.show .modal-dialog {
    width: 70% !important;
  }
}

@media (max-width: 575px) {
  .filtermodal.modal.show .modal-dialog {
    width: 80% !important;
  }
}
/* 
@media (max-width: 475px) {
  .filtermodal.modal.show .modal-dialog {
    width: 80% !important;
    margin: auto !important;
  }
} */

@media (max-width: 380px) {
  .dateBody {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .filtermodal.modal.show .modal-dialog {
    width: 90% !important;
  }
}

/* @media (max-width: 335px) {
  .filtermodal.modal.show .modal-dialog {
    width: 320px !important;
    margin: auto !important;
  }
} */

.grid-container {
  display: grid;
  gap: 10px;
  row-gap: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 30px;
  height: -webkit-fill-available-;
  object-fit: cover;
}

.grid-item img {
  height: -webkit-fill-available;
  object-fit: cover;
}

.item2 {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
}

.item1 {
  grid-column: 2;
  grid-row: 2 / span 1;
}

.item0 {
  grid-column: 2 / span 1;
  grid-row: 1;
}

.individualScore {
  display: flex;
  justify-content: space-between;
  background: #d9d9d980;
  padding: 5px;
  border-radius: 5px;
}

.leadlist {
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #e9ecef !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4 {
  box-shadow: none;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0px;
}

.loaderClass {
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: "blur(2px)";
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4 {
  box-shadow: none;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0px;
}

.loaderClass {
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: "blur(2px)";
}

/* .btnReject{
    border-color: red !important;
    background: transparent !important;
    color: red !important;
}
.btnApprove{
    border-color: green !important;
    background: transparent !important;
    color: green !important;
} */

.btnReject {
  border: 2px solid red;
  color: red;
  border-radius: 5px;
  z-index: 1;
  background: transparent;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
  margin-left: 5px;
}

.btnReject::before {
  content: "";
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: red;
  z-index: -1;
  transition: all 250ms;
}

.btnReject:hover {
  color: #fff;
  border: 2px solid red;
  background: transparent;
}

.btnReject:hover::before {
  width: 100%;
}

.btnApprove {
  border: 2px solid green;
  border-radius: 5px;
  color: green;
  z-index: 1;
  background: transparent;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
}

.btnApprove::before {
  content: "";
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: green;
  z-index: -1;
  transition: all 250ms;
}

.btnApprove:hover {
  color: #fff;
  border: 2px solid green;
  background: transparent;
}

.btnApprove:hover::before {
  width: 100%;
}

@media (max-width: 991px) {
  .btnApprove {
    margin-right: 6px !important;
  }
}

.property-list .MuiTableCell-root.MuiTableCell-body,
.property-list .MuiTableCell-root.MuiTableCell-head {
  padding: 10px 10px 10px 8px;
}

.user-list .MuiTableCell-root.MuiTableCell-body,
.user-list .MuiTableCell-root.MuiTableCell-head {
  padding: 10px 10px 10px 0px;
}

.staff-list .MuiTableCell-root.MuiTableCell-body,
.staff-list .MuiTableCell-root.MuiTableCell-head {
  padding: 10px 10px 10px 0px;
}

/* .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  margin-left: -15px !important;
} */

.font-weight-bold {
  font-weight: 700;
  font-weight: 700;
}

.login_btn_mobile {
  display: none;
}

@media (max-width: 599.95px) {
  .tss-qbo1l6-MUIDataTableToolbar-actions {
    text-align: right !important;
  }

  .login_btn_desktop {
    display: none;
  }
}

.divider {
  content: "";
  height: 1.5px;
  width: 100%;
  position: absolute;
  background: #f2f2f2;
  left: 0;
  right: 0;
  margin: 5px 0px;
}

.roomDHeader {
  border-bottom: 1px solid;
}

.roomdetails {
  border: 1px solid;
  border-radius: 5px;
}

.img_del {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: red !important;
}

.facilitybtn {
  margin-right: 5px;
  border-radius: 5px !important;
  width: 20%;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ensure the .zoneType div is a flex container */
.zonetype > div {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  /* Adjust as per your design */
}

.zonetype .custom-button {
  flex-basis: 48% !important;
  /* Adjust this value based on the gap you need between buttons */
  margin-bottom: 10px !important;
  /* Adjust or remove according to your design */
}

/* Media query for screens smaller than 470px */
@media (max-width: 469px) {
  .buttonContainerzone .btnNormal,
  .buttonContainerzone .btnActive {
    flex-grow: 1 !important;
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
  }

  .zonetype .custom-button {
    flex-basis: 100% !important;
    /* Make each button take full width */
  }
}

.buttonContainerHubLoc .btnNormal,
.buttonContainerHubLoc .btnActive {
  flex-grow: 1 !important;
  width: calc(33.33% - 10px) !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.comm-button-group-container .btnNormal,
.comm-button-group-container .btnActive {
  flex-grow: 1 !important;
  width: calc(33.33% - 10px) !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

@media (max-width: 699px) {
  .comm-button-group-container .btnNormal,
  .comm-button-group-container .btnActive {
    flex-grow: 1 !important;
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 699px) {
  .buttonContainerHubLoc .btnNormal,
  .buttonContainerHubLoc .btnActive {
    flex-grow: 1 !important;
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 601px) {
  .buttonContainerPropCondt .btnNormal,
  .buttonContainerPropCondt .btnActive {
    flex-grow: 1 !important;
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 469px) {
  .retailshops .btnNormal,
  .retailshops .btnActive {
    flex-grow: 1 !important;
    width: calc(50% - 10px) !important;
    margin-right: 10px !important;
  }

  .retailshoper .custom-button {
    flex-basis: 100% !important;
    /* Make each button take full width */
  }
}

@media (max-width: 558px) {
  .ownerShips {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    /* Adjust spacing between buttons */
  }

  .ownerShips .btnNormal,
  .ownerShips .btnActive {
    flex: 0 0 calc(50% - 5px) !important;
    /* Adjust the '5px' as necessary to account for the gap you want */
    width: calc(50% - 5px) !important;
    /* This width calculation allows for two buttons per line */
    margin-right: 0 !important;
    /* Reset any right margin to avoid issues */
    margin-bottom: 10px !important;
    /* Space between rows, adjust as needed */
  }

  /* Removing the unnecessary custom button class adjustments as they don't appear in your button class list */
}

@media (max-width: 469px) {
  .customProp {
    width: calc(50% - 5px) !important;
  }
}

@media (max-width: 355px) {
  .custombtnProp {
    font-size: 12px !important;
  }
}

.comm-button-group-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1366px) {
  .MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    /* Add your custom styles here */
    margin-left: 64px;
    font-size: 15px;
    font-weight: 400;
    color: #438ceb;
    /* Change text color */
    border: none;
    padding: 8px 16px;
    /* Adjust padding */
  }
}

@media (max-width: 992px) {
  .MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    /* Add your custom styles here */
    margin-left: 70px;
    font-size: 15px;
    font-weight: 400;
    color: #438ceb;
    /* Change text color */
    border: none;
    padding: 8px 16px;
    /* Adjust padding */
  }
}

@media (max-width: 512px) {
  .MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    /* Add your custom styles here */
    margin-left: 71px;
    font-size: 15px;
    font-weight: 400;
    color: #438ceb;
    /* Change text color */
    border: none;
    padding: 8px 16px;
    /* Adjust padding */
  }
}

@media (max-width: 925px) {
  .css-1ygcj2i-MuiTableCell-root,
  .tss-1akey0g-MUIDataTableHeadCell-data,
  .css-1ex1afd-MuiTableCell-root {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 792px) {
  .css-1ygcj2i-MuiTableCell-root,
  .tss-1akey0g-MUIDataTableHeadCell-data,
  .css-1ex1afd-MuiTableCell-root {
    font-size: 0.9rem !important;
  }
}

@media only screen and (max-width: 399px) and (min-width: 0px) {
  label {
    font-size: 14px;
  }

  input::placeholder {
    font-size: 14px;
  }
}

.dzu-dropzone {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  min-height: 200px !important;
  overflow: auto !important;
  overflow-y: scroll !important;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  flex-wrap: wrap;
  background: linear-gradient(
    78.28deg,
    rgba(0, 160, 227, 0.1),
    rgba(0, 102, 171, 0.1)
  );
}

.dzu-previewImage {
  width: 100px !important;
  height: 100px !important;
  max-height: 100px !important;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
  width: 100px !important;
}

.dzu-previewContainer {
  padding: 20px 2% !important;
  display: block !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: auto;
  min-height: 60px;
  z-index: 1;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}

.dzu-inputLabelWithFiles {
  display: flex;
  width: 100% !important;
  justify-content: center;
  margin: 0 18px !important;
  align-items: center;
  padding: 0 14px;
  min-height: 32px;
  background-color: #e6e6e6;
  color: #2484ff;
  border: none;
  font-family: "Helvetica", sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

@media (max-width: 599.95px) {
  .tss-qbo1l6-MUIDataTableToolbar-actions {
    text-align: right !important;
  }
}

.back-img {
  position: relative !important;
  overflow: hidden !important;
  height: auto !important;
  background-image: url("../images/backhome.png") !important;
  background-size: cover !important;
}

.back-imgf {
  position: relative !important;
  overflow: hidden !important;
  height: 600px !important;
  background-image: url("../images/backhome.png") !important;
  background-size: cover !important;
}

.backimg2 {
  position: relative !important;
  overflow: hidden !important;
  height: auto !important;
  background-image: url("../images/backhome.png") !important;
  background-size: cover !important;
}

.heading {
  font-weight: 800 !important;
  font-size: 47px !important;
  padding: 0px 0px 30px 40px !important;
  color: #000;
}

.head1 {
  font-size: 23px !important;
  font-weight: 800 !important;
}

.head2 {
  font-size: 23px !important;
  font-weight: 400 !important;
}

.back-img1 {
  display: block !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  padding-top: 20px !important;
  margin: auto !important;
  height: 50vh !important;
  width: 23vw !important;
}

.back-img2 {
  display: block !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  height: 50vh !important;
  width: 23vw !important;
}

.headimg {
  height: 32px !important;
  width: 23px !important;
}

@media (max-width: 899px) {
  .back-img {
    display: none !important;
  }
}

@media (max-width: 899px) {
  .logoImg {
    display: flex !important;
    align-items: center;
  }
}

@media (max-width: 553px) {
  .css-1xxajqc-MuiTypography-root {
    font-size: 20px !important;
  }

  .css-j204z7-MuiFormControlLabel-root {
    font-size: 12px !important;
  }
}

@media (max-width: 500px) {
  .css-1xxajqc-MuiTypography-root {
    font-size: 18px !important;
  }

  .css-lmgy04-MuiTypography-root {
    font-size: 15px !important;
  }

  .registerbtn {
    font-size: 12px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
  }
}

.css-b1im03-MuiGrid-root {
  max-width: 100% !important;
}

@media (max-width: 460px) {
  .css-1xxajqc-MuiTypography-root {
    font-size: 17px !important;
  }

  .css-1m9pwf3,
  .css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
  }

  .forgetbtn {
    font-size: 12px !important;
  }
}

@media (max-width: 356px) {
  .css-1m9pwf3,
  .css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
  }

  .forgetbtn {
    font-size: 10px !important;
    height: 30px !important;
  }

  .css-lmgy04-MuiTypography-root {
    font-size: 13px !important;
  }

  .registerbtn {
    font-size: 10px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 300px) {
  .css-1m9pwf3,
  .css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
  }

  .forgetbtn {
    font-size: 9px !important;
    height: 30px !important;
  }
}

.container-fluid {
  overflow: hidden !important;
}

@media (max-width: 899px) and (min-width: 800px) {
  .container-fluid {
    width: 100% !important;
  }
}

@media (max-width: 799px) and (min-width: 700px) {
  .container-fluid {
    width: 100% !important;
  }
}

@media (max-width: 699px) and (min-width: 590px) {
  .container-fluid {
    width: 85% !important;
  }
}

@media (max-width: 400px) and (min-width: 370px) {
  .mainBox {
    width: 80% !important;
    margin: auto !important;
  }
}

@media (max-width: 369px) and (min-width: 300px) {
  .mainBox {
    width: 90% !important;
    margin: auto !important;
  }
}

/* @media (max-width:649px) and (min-width:550px){
    .mainBox{
      padding:0px 90px !important;
    }
    } */

/* @media (max-width:550px){
  .mainBox{
    padding:0px 40px !important;
  }
  } */

@media (min-width: 900px) {
  .logindivborder,
  .orline {
    display: none;
  }
}

@media (max-width: 900px) {
  .loginbutton {
    display: none;
  }
}

.right-side-container {
  overflow-y: auto !important;
  height: 100vh !important;
  /* Adjust the height as needed */
}

/* Adjusting the tabs container width for smaller screens */
@media (max-width: 575.98px) {
  .wizard > .steps > ul > li {
    width: auto;
    /* Change the width to auto for smaller screens */
  }
}

/* Ensuring the tabs are visible even on smaller screens */

/* .modal-content {
  width: 50%!important; Adjust this value as needed
  margin: auto !important;
} */
/* Media query for screens with a maximum width of 600px */
/* @media (max-width: 768px) {
  Adjust the width of the modal for smaller screens
  .modal-content {
    width: 70% !important; Adjust this value as needed
    margin: auto !important;
  }
} */
/* 
@media (max-width: 700px) {
  Adjust the width of the modal for smaller screens
  .modal-content {
    width: 70% !important; Adjust this value as needed
    margin: auto !important;
  }
} */

.css-dmmspl-MuiFormGroup-root {
  flex-wrap: nowrap !important;
}

@media (max-width: 515px) {
  .css-dmmspl-MuiFormGroup-root {
    flex-wrap: wrap !important;
  }

  .css-j204z7-MuiFormControlLabel-root {
    margin-bottom: -7px !important;
  }

  .buttons {
    margin: 35px 0px 10px 0px !important;
  }
}

.css-j204z7-MuiFormControlLabel-root {
  margin-right: 5px !important;
}

@media (max-width: 500px) and (min-width: 350px) {
  /* Adjust the width of the modal for smaller screens */
  .genders {
    margin-left: 0px !important;
    /* Adjust this value as needed */
  }
}

@media (max-width: 304px) {
  .genders {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.plan-modal-content {
  width: 90%;
  /* Adjust the width as needed */
  max-width: 500px;
  /* Optional: Set a maximum width */
  margin: 0 auto;
  /* Center the modal horizontally */
  background-color: white;
  padding: 20px;
  max-height: 600px;
  overflow: auto;
}

@media (max-width: 380px) {
  .MuiButtonBase-root.MuiButton-root {
    .MuiButtonBase-root.MuiButton-root {
      height: 50px !important;
      width: 100px !important;
    }
  }
}

@media (max-width: 350px) {
  .MuiButtonBase-root.MuiButton-root {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 1050px) {
  .otpbtn {
    width: 70% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }
}

@media (max-width: 850px) {
  .otpbtn {
    width: 70% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }
}

@media (max-width: 600px) {
  .otp-input {
    width: 55px !important;
  }
}

@media (max-width: 520px) {
  .otpheader {
    font-size: 15px !important;
  }

  .otpheader2 {
    font-size: 14px !important;
  }

  .otp-input {
    width: 50px !important;
  }

  .otpbtn {
    width: 80% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }

  .resentotp {
    font-size: 12px !important;
  }
}

@media (max-width: 420px) {
  .otpheader {
    font-size: 14px !important;
  }

  .optbtntext {
    font-size: 12px !important;
  }

  .otpbtn {
    width: 70% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }

  .otp-input {
    width: 50px !important;
  }
}

@media (max-width: 380px) {
  .otpheader {
    font-size: 14px !important;
  }

  .otpheader2 {
    font-size: 13px !important;
  }

  .otp-input {
    width: 45px !important;
  }

  .otpbtn {
    width: 100% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }

  .optbtntext {
    font-size: 12px !important;
  }
}

@media (max-width: 325px) {
  .otpbtn {
    width: 100% !important;
    margin: 10px auto !important;
    /* Center the button horizontally */
  }

  .optbtntext {
    display: flex !important;
    justify-content: center !important;
    font-size: 10px !important;
  }
}

.otp-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 575px) {
  .tabs2 {
    display: block !important;
    font-size: 12px !important;
  }
}

@media (max-width: 332px) {
  .tabs2 {
    display: block !important;
    font-size: 12px !important;
  }
}

@media (max-width: 336px) {
  .withhead {
    font-size: 15px !important;
  }
}

@media (max-width: 384px) {
  .addbutton {
    padding: 8px 20px !important;
  }
}

@media (max-width: 315px) {
  .addbtn {
    font-size: 10px !important;
  }
}

@media (max-width: 768px) {
  .modalwithdraw {
    width: 120% !important;
  }
}

.modalblog {
  width: 100% !important;
}

@media (max-width: 599px) {
  .modalblog {
    width: 120% !important;
  }
}

@media (max-width: 450px) {
  .modalblog {
    width: 112% !important;
  }
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    width: 70% !important;
    margin: auto !important;
  }
}

@media (max-width: 450px) {
  .modal.show .modal-dialog {
    width: 85% !important;
    margin: auto !important;
  }
}

.maleGen {
  margin-right: 0px !important;
  margin-left: 0rem !important;
}

.notiText {
  position: absolute;
  top: 0;
  width: max-content;
  padding: 5px 20px;
  background: red;
  color: aliceblue;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.expiredNotification {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
}

.notifybtn {
  padding: 0;
  background: none;
  border: none;
  vertical-align: baseline;
  color: white;
  font-weight: 700;
  text-decoration: underline;
}

/* .NavItem {
  display: block; Default display
}

Styles for <ul> to ensure horizontal layout on narrower screens
@media (max-width: 992px) {
  ul .listTabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; Adjust this as per your design requirements
    padding: 0;
    list-style-type: none; Removes default <ul> styling
  }
  .NavItem {
    display: flex; Makes each NavItem a flex container to align NavLink and icons
    margin: 0.5rem; Adds some space between the tabs
    flex: 1 1 auto; Adjusts the basis and allows items to grow and shrink
  }
  .NavLink {
    display: flex;
    align-items: center; Centers items vertically
    justify-content: center; Centers items horizontally
  }
  Add more styling here as needed
} */

.modalBlog2 {
  width: 45vw !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  max-height: 90vh !important;
  overflow-y: auto !important;
}

@media (max-width: 1199px) {
  .modalBlog2 {
    width: 60vw !important;
  }
}

@media (max-width: 799px) {
  .modalBlog2 {
    width: 80vw !important;
  }
}

@media (max-width: 469px) {
  .modalBlog2 {
    width: 95vw !important;
  }
}

@media (max-width: 767px) {
  .blogTitle {
    margin-bottom: 20px !important;
  }
}

.modal-body .userModalBody {
  width: 70% !important;
}

@media (max-width: 768px) {
  .modal-body .userModalBody {
    width: 80% !important;
  }
}

@media (max-width: 499px) {
  .modal-body .userModalBody {
    width: 90% !important;
  }
}

@media (max-width: 991px) {
  .number .completetab,
  .number .activetab {
    display: flex !important;
    /* flex-wrap: wrap !important; */
  }
  .wizard ul.listTabs {
    display: flex !important;
    overflow: auto;
    justify-content: center;
  }
  .wizard .steps .nav-link {
    background-color: transparent;
    color: #000;
    display: flex !important;
    flex-wrap: wrap;
    width: 120px;
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  .wizard > .steps .number {
    width: 45px;
    height: 45px !important;
    margin: 0px !important;
    position: relative !important;
    display: flex !important;
  }
  .number::after {
    background: linear-gradient(78.28deg, #00a0e3 0%, #0066ab 99.35%);
    content: "";
    width: calc(50% + 55px) !important;
    height: 2px !important;
    position: absolute;
    left: 111% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

@media (max-width: 699px) {
  .wizard ul.listTabs {
    display: flex !important;
    overflow: auto;
    justify-content: space-between;
    position: relative !important;
  }
}
/* .col-lg-12 .basicDetailPropbtn{
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
}
.basicDetailPropbtn > .btn2 {
  flex-basis: calc(50% - 10px) !important; Adjust width for two buttons per row with margin
  margin: 5px !important; Margin between buttons
} */

.btnEqualWidth {
  flex: 1 !important;
  /* Make buttons flexible */
  margin-bottom: 10px !important;
  margin-right: 5px !important;
  border-radius: 5px !important;
}

.appartment {
  width: 20% !important;
  /* Full width for each button */
}

.villa {
  width: 18% !important;
  /* Full width for each button */
}

.independentH,
.independentF {
  width: 29% !important;
}

.agriculturalLand {
  width: calc(40%) !important;
  /* Full width for each button */
}

.plot {
  width: calc(20%) !important;
  /* Full width for each button */
}

/* @media(max-width:375px) {

  .agriculturalLand {
    width: 50% !important;
    Full width for each button
  }

  .plot {
    width: 20% !important;
    Full width for each button
  }
} */

@media (max-width: 1250px) {
  .appartment {
    width: calc(22% - 5px) !important;
    /* Full width for each button */
  }

  .villa {
    width: calc(15% - 5px) !important;
    /* Full width for each button */
  }

  .independentH,
  .independentF {
    width: calc(29% - 5px) !important;
  }
}

@media screen and (max-width: 633px) {
  .btnEqualWidth {
    width: calc(50% - 5px) !important;
    /* Calculate 50% width minus margin-right */
  }
}

@media screen and (max-width: 430px) {
  .btnEqualWidth {
    font-size: 13px !important;
    /* Adjust font size */
    padding: 8px 10px !important;
    /* Adjust padding */
  }
}

@media (max-width: 633px) {
  .agriculturalLand {
    width: calc(63%) !important;
    /* Full width for each button */
  }

  .plot {
    width: calc(30%) !important;
    /* Full width for each button */
  }
}

@media (max-width: 633px) {
  .agriculturalLand {
    width: calc(63%) !important;
    /* Full width for each button */
  }

  .plot {
    width: calc(30%) !important;
    /* Full width for each button */
  }
}

@media screen and (max-width: 400px) {
  .appartment,
  .villa {
    width: 37% !important;
    /* Full width for each button */
  }

  .independentH,
  .independentF {
    width: 55% !important;
  }
}

@media screen and (max-width: 362px) {
  .villa {
    width: 32% !important;
    /* Full width for each button */
  }

  .independentH {
    width: 63% !important;
  }
}

/* @media screen and (max-width: 500px) {
  .buttonContainer .btnActive, .btnNormal {
    width: 15% !important;
    margin-bottom: 10px !important;
  }
} */
/* External CSS file */
.buttonContainer .btnNormal,
.buttonContainer .btnActive {
  flex-grow: 1 !important;
  width: calc(20% - 10px) !important;
  /* 20% to ensure 5 buttons in a row, subtract margin */
  margin-right: 10px !important;
  /* Adjust margin between buttons */
}

/* washroom */

@media (max-width: 1050px) and (min-width: 992px) {
  .buttonContainer {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainer .btnNormal,
  .buttonContainer .btnActive {
    max-width: calc(14% - 10px) !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 625px) {
  .buttonContainer {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainer .btnNormal,
  .buttonContainer .btnActive {
    max-width: calc(14% - 10px) !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 500px) {
  .buttonContainer {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainer .btnNormal,
  .buttonContainer .btnActive {
    max-width: calc(20% - 10px) !important;
    margin-bottom: 10px !important;
  }
}

/* parking */
@media (max-width: 500px) {
  .buttonContainer2 {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainer2 .btnNormal,
  .buttonContainer2 .btnActive {
    width: calc(20% - 10px) !important;
    margin-bottom: 10px !important;
  }
}

/* bhk */

@media (max-width: 1340px) and (min-width: 992px) {
  .buttonContainerbhk {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainerbhk .btnNormal,
  .buttonContainerbhk .btnActive {
    width: calc(17%) !important;
    /* Adjust percentage as needed */
    margin-bottom: 10px !important;
  }
}

@media (max-width: 740px) {
  .buttonContainerbhk {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainerbhk .btnNormal,
  .buttonContainerbhk .btnActive {
    width: calc(17%) !important;
    /* Adjust percentage as needed */
    margin-bottom: 10px !important;
  }
}

@media (max-width: 458px) {
  .buttonContainerbhk {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    /* Ensures equal spacing between buttons */
  }

  .buttonContainerbhk .btnNormal,
  .buttonContainerbhk .btnActive {
    width: calc(25% - 10px) !important;
    /* Adjust percentage and margin as needed */
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  /* Adjust the width of the last row's buttons */
  .buttonContainerbhk:after {
    content: "";
    flex-basis: calc(20% - 10px);
    /* Width same as other buttons */
  }
}

.buttonContainerTenant .btnNormal,
.buttonContainerTenant .btnActive {
  width: calc(33.33% - 10px) !important;
  margin-bottom: 10px !important;
}

@media (max-width: 360px) {
  .buttonContainerTenant {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
  }

  .buttonContainerTenant .btnNormal,
  .buttonContainerTenant .btnActive {
    width: calc(50% - 10px) !important;
    margin-bottom: 10px !important;
  }
}

/* forget button */
@media (max-width: 599px) {
  .login-button-container {
    display: flex !important;
    justify-content: center !important;
    margin-top: 20px !important;
  }

  .login-button-container .freehousing_button {
    width: 300px !important;
    /* Adjust width as needed */
    height: 40px !important;
    /* Adjust height as needed */
    justify-content: center !important;
    /* Ensure button text is centered */
    margin-bottom: 10px !important;
    /* Adjust margin as needed */
  }

  .forgetLogin {
    display: none !important;
  }

  .forgetHead {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }
}

.otp-input2 {
  width: 15% !important;
}

@media (max-width: 400px) {
  .forgetMain {
    width: 90% !important;
  }

  .otp-input2 {
    width: 15% !important;
  }

  .login-button-container {
    display: flex !important;
    justify-content: center !important;
    margin-top: 20px !important;
  }
}

@media (max-width: 767px) {
  .forgetMain {
    width: 90% !important;
  }
}

.roomDetails {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
}

@media screen and (max-width: 472px) {
  .roomDetails .btnEqualWidth {
    flex-basis: calc(50% - 5px) !important;
    margin-right: 5px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 1435px) {
  .facilitybtn {
    flex-basis: calc(25% - 5px) !important;
    white-space: normal !important;
    /* Allow text to wrap naturally */
  }
}

@media screen and (max-width: 799px) {
  .facilitybtn {
    flex-basis: calc(25% - 5px) !important;
    white-space: normal !important;
    /* Allow text to wrap naturally */
  }
}

@media screen and (max-width: 473px) {
  .facilitybtn {
    min-width: calc(50% - 5px) !important;
    white-space: normal !important;
    /* Allow text to wrap naturally */
  }
}
.btnActivepostedby {
  background: linear-gradient(78.28deg, rgb(0 160 227), rgb(0 102 171));
  border: 1px solid transparent;
  color: white;
}

.built-up input {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.built-up span span {
  background-color: var(--bs-secondary-bg);
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* .built-up-span {
  color: var(--bs-body-color);
  border-color: #b1b7ea;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(98, 110, 212, 0.25);
} */

@media (max-width: 464px) {
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }

  /* customPaginationStyles.css */

  /* First line */
  .css-pdct74-MuiTablePagination-selectLabel,
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    flex-basis: calc(39%) !important;
    /* Adjust the spacing between elements */
  }

  /* Second line */
  .css-levciy-MuiTablePagination-displayedRows,
  .MuiTablePagination-actions {
    flex-basis: calc(44%) !important;
    /* Adjust the spacing between elements */
  }
}

.uploadImageField {
  margin-bottom: 10px !important; /* Adjust margin as needed */
}

@media (max-width: 600px) {
  .planListModel {
    margin-top: 35px !important;
  }
}
@media (max-width: 600px) {
  #layout-wrapper
    > div.main-content
    > div.page-content
    > div
    > div
    > div.p-0.pt-2.pb-2.col-12.col-md-12.col-lg-8
    > div
    > div,
  #layout-wrapper
    > div.main-content
    > div.page-content
    > div
    > div
    > div.p-0.pt-2.pb-2.col-12.col-md-12.col-lg-8
    > div
    > div
    > div
    > div.content.clearfix,
  #layout-wrapper
    > div.main-content
    > div.page-content
    > div
    > div
    > div.p-0.pt-2.pb-2.col-12.col-md-12.col-lg-8
    > div
    > div
    > div
    > div.actions.clearfix
    > ul
    > li.previous {
    padding: 5px !important;
  }
  #layout-wrapper
    > div.main-content
    > div.page-content
    > div
    > div
    > div.p-0.pt-2.pb-2.col-12.col-md-12.col-lg-8
    > div
    > div
    > div
    > div.actions.clearfix {
    height: 50px;
  }
  .preImage {
    width: 100px;
    height: 100px;
    margin: 0px 20px 20px 0px !important;
  }
}

@media (max-width: 400px) {
  .sideBorder {
    margin-right: 15px;
  }
  .sideButton {
    margin-left: -15px !important;
  }
}

.uploadImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.uploadImageFieldWrapper {
  flex-grow: 1;
  margin-right: 10px;
}

.uploadImageField {
  width: 100%;
}

.fileInputButton {
  height: fit-content;
}
/* 
@media(max-width:1500px){
  .dzu-previewImage{
    width: 115px !important;
  }
} */

@media (max-width: 400px) and (min-width: 300px) {
  .radiogrp {
    margin-left: 10px !important;
  }
}

/* .tss-1akey0g-MUIDataTableHeadCell-data{
  margin-left: -18px !important;
} */

.css-1h7anqn {
  display: flex !important;
  flex-flow: row !important;
}

.MuiTableCell-root.MuiTableCell-head .MuiButtonBase-root {
  text-align: left !important;
  width: max-content;
  padding-left: 0px !important;
}
.navbar-brand-box {
  padding-right: 0.5rem;
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: block;
  }
}
@media (max-width: 900px) {
  .mini-stat {
    height: auto !important;
  }
}
.MuiTableCell-root.MuiTableCell-head div{
  width: max-content;
}
.ql-toolbar.ql-snow{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow{
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}